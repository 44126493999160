import React, { FC, useContext } from 'react';
import styled from 'styled-components';
import { Box, Heading, Text } from 'grommet';
import { ILink } from '../utils/componentInterfaces';
import Button from './button';
import HtmlContent from './htmlContent';
import { createMarkup } from '../utils';
import { MaxWidth } from './maxWidth';
import { HeaderPopupContext } from '../context';
import ActionButtons from './actionButtons';

export interface Props {
    buttonText?: string;
    content?: string;
    heading: string;
    link?: ILink;
    style?: string;
    linkOrPopup: 'popup' | 'link' | 'none' | 'both';
}

const StyledContent = styled(Box)`
    text-align: center;
`;
const Cta: FC<Props> = ({ buttonText, intro, content, heading, link, style, linkOrPopup }) => {
    const { onOpen } = useContext(HeaderPopupContext);

    return (
        <Box
            background={style === 'standard' ? 'accent-1' : { color: 'brand', dark: true }}
            className={style === 'standard' ? 'accent-1' : 'brand'}
        >
            <MaxWidth align="center">
                <Box pad={{ vertical: 'xlarge' }} align="center" width={{ max: '600px' }}>
                    <Heading level="2" margin={content ? 'none' : { bottom: 'large' }} textAlign="center" color="white">
                        {heading}
                    </Heading>
                    {intro && (
                        <Heading level="3" margin="none" textAlign="center" color="white">
                            {intro}
                        </Heading>
                    )}
                    {content && (
                        <StyledContent margin={{ bottom: 'medium', top: 'medium' }}>
                            <Text as="div" color="white">
                                <HtmlContent textAlign="center" dangerouslySetInnerHTML={createMarkup(content)} />
                            </Text>
                        </StyledContent>
                    )}
                    {buttonText && linkOrPopup === 'link' && link && (
                        <Button label={<Text color="white">{buttonText}</Text>} to={link.uri} color="light-1" reverse />
                    )}
                    {buttonText && linkOrPopup === 'popup' && (
                        <Button
                            label={<Text color="white">{buttonText}</Text>}
                            color="light-1"
                            reverse
                            onClick={onOpen}
                        />
                    )}
                    {linkOrPopup === 'both' && <ActionButtons />}
                </Box>
            </MaxWidth>
        </Box>
    );
};

export default Cta;
